import { Link } from 'react-router-dom';
import './style.css';
import { useEffect, useState } from 'react';


function Cover ({isOpened, setIsOpened}) {

    const [responsive, setResponsive] = useState(window.innerWidth > 1024);

    useEffect(() => {
        const handleResize = () => {
            setResponsive(window.innerWidth > 1024);
        };
        
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
        }, []);


    const scrollToFlipSide = () => {
        document.getElementById("flip-side").scrollIntoView({behavior:'smooth'});
    }

    return(
        <section id='cover' className='cover-component' style={{backgroundImage:'url(/img/kitchen1.png)'}} >
            <div className='container'>
                <div className='textbox' style={{ backgroundColor: responsive ? 'rgba(53, 53, 51, 0.69)' : 'transparent' }}>
                    {responsive ? (
                        <div className='desktop-text'>
                            <h1 className='cover-component-title'>
                                Üdvözli Önt a Haffner Bútor!
                            </h1>
                            <h2 className='cover-component-headline'>
                                Kézműves precizitás, időtálló dizájn!
                            </h2>
                        </div>
                                ) : (
                    <img className='responsive-logo' src='/img/haffnerlogo.png'/>
                    )}
                    <button className='cover-component-button' onClick={() => scrollToFlipSide()} style={{backgroundImage:'url(/img/woodbutton.jpg)'}} >
                        Tovább
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Cover;