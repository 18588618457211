import ContentCircles from '../../components/ContentCircles';
import FullWidthAnimated from '../../components/FullWidthAnimated';
import './style.scss';


function Services () {
    return(
        <section className='service-screen'>
            <h1 className='title'> Szolgáltatásaink </h1>
            <ContentCircles/>
        </section>
    )
}

export default Services;