import './style.scss';

function AboutUs (scrollToFlipSide) {
    return(
        <section className='screen-aboutus' style={{backgroundImage:'url(/img/haffnerbutor.png)'}}>
            <div className='container'>
                <article className='main-article'>
                    <h1 className='title'>
                        RÓLUNK
                    </h1>
                    <div className='description'>
                        <p>
                        Üdvözöljük cégünknél, ahol több mint 30 év tapasztalatával alakítjuk át ügyfeleink otthonait! Szakértelmünkkel és elkötelezettségünkkel a legkiválóbb minőségű bútorokat kínáljuk, amelyek ötvözik a modern dizájnt a funkcionalitással. Minden egyes projektünknél a részletekre ügyelünk, hogy a végén egyedi és tartós megoldásokat nyújtsunk ügyfeleinknek. Bízzon bennünk, és tapasztalja meg, hogyan válhat konyhája az otthona szívévé!  
                        </p>
                    </div>
                </article>
            </div>
        </section>
    )
}

export default AboutUs;