import { Link } from 'react-router-dom';
import './style.css';

function FlipSide ({title, imageUrl, reverse, description, backgroundColor, color}) {


    return(
        <section id='flip-side' className={'flip-side ' + (reverse === true ? 'reverse' : '' )}>
            <div style={{backgroundImage:'url('+ imageUrl +')'}} className='image'></div>
            <div className='content' style={{background:backgroundColor, color:color}}>
                <h2 className='title'>
                    {title}
                </h2>
                <p className='description'>
                    {description}
                </p>
                <Link to={'/services'}>
                    <button className='button'>
                        Tovább
                    </button>
                </Link>
            </div>
        </section>
    )
}

export default FlipSide;