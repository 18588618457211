import './style.scss';

function ScrollBackground ({reverse}) {
    return(
        <section className='scrollbackground-component'>
            <div className='first-layer' style={{backgroundImage:'url(/img/kitchen5.png)'}}>
                <h2 className='headline'>
                    1.Igényfelmérés
                </h2>
                <p className='description'>
                    Az igényfelmérés egy kulcsfontosságú lépés a sikeres projektek megvalósításában. Ennek során alaposan megismerjük ügyfeleink elvárásait, preferenciáit és a rendelkezésre álló hely adottságait. Az egyeztetés alatt személyre szabott javaslatokat teszünk, amelyek tökéletesen illeszkednek az egyedi igényekhez, biztosítva ezzel a maximális elégedettséget.
                </p>
            </div>
            <div className='second-layer' style={{backgroundImage:'url(/img/kitchenplan.jpg)'}}>
                <h2 className='headline'>
                   2.Tervezés
                </h2>
                <p className='description'>
                    A tervezés során összehangoljuk az esztétikai szempontokat a funkcionalitással, hogy a végeredmény egyszerre legyen szép és praktikus. Célunk, hogy minden részlet tökéletesen illeszkedjen az egyedi igényekhez, így hozva létre egy harmonikus és tartós teret.
                </p>
            </div>
            <div className='third-layer' style={{backgroundImage:'url(/img/kitchenprocess.jpg)'}}>
                <h2 className='headline'>
                    3.Kivitelezés
                </h2>
                <p className='description'>
                    A kivitelezés során a tervezett koncepciót precízen valóra váltjuk, figyelembe véve minden részletet és minőségi követelményt. Célunk, hogy a tervezett bútorok és megoldások hibátlanul megvalósuljanak, biztosítva a tartósságot és a tökéletes funkcionalitást.
                </p>
            </div>
        </section>
    )
}

export default ScrollBackground;