import { Outlet } from 'react-router';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';

function DefaultLayout({cartItems}) {
  
    return (
          <div className='default-layout'>
            <Navigation />
            <Outlet />
            <Footer />
          </div>
      );
    }
    
    export default DefaultLayout;