import './style.scss';

function Footer () {
    

    return(
        <footer className="footer">
            <div className="container">
            <div className="footer-content">
                <div className="footer-block" >
                    <h2 className="headline">
                        Információk
                    </h2>
                    <ul className="information-links">
                        <li>
                            <a href="/">
                                KEZDŐLAP
                            </a>
                        </li>
                        <li>
                            <a href="/about-us">
                                RÓLUNK
                            </a>
                        </li>
                        <li>
                            <a href="/services">
                                SZOLGÁLTATÁSOK
                            </a>
                        </li>
                        <li>
                            <a href="/contact">
                                KAPCSOLAT
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="logo">
                    <img src="/img/haffnerlogo.png" className="footer-logo"/>
                </div>
                <div className="footer-block">
                    <h2 className="headline">
                        Elérhetőségek
                    </h2>
                    <p className="footer-contact-information">
                        <span>Cím:</span> 2600 Vác, Derecske dűlő 10.
                    </p>
                    <p className="footer-contact-information">
                        <span>Nyitvatartás:</span>  Hétfő - Péntek: 09:00 - 16:00
                    </p>
                    <p className="footer-contact-information">
                        <span>Telefon:</span> <a href="tel:+36704209233" > +36-70-420-9233 </a>
                    </p>
                    <p className="footer-contact-information">
                        <span>E-mail:</span> <a href="mailto:steuermarkt@gmail.com"> info@haffnerbutor.hu </a>
                    </p>
                </div>
            </div>
    </div>
</footer>
    )
}
export default Footer;