import './style.scss'

function TileComponent () {
    return(
        <section className='tile-component' style={{backgroundImage:'url(img/woodbutton.jpg)'}}>
            <div className='container'>
                <h1 className='title'>
                   Több, mint 30 év tapasztalat
                </h1>
                <h3 className='headline'>
                    Több projektben volt már szerencsénk részt venni, amelyekben az ügyfeleink elégedettsége új szintre emelte a Haffner Bútor hírnevét.
                </h3>
                <div className='cards'>
                    <div className='card'>
                        <img className='card-image' src='/img/pannonlogo.png'/>
                        <div className='card-content'>
                            <h3 className='card-title'>
                                Pannonia Filmstúdió (TV2 székház)
                            </h3>
                            <p className='card-description'>
                                Pannonia Filmstúdió csúcskategóriás bútorainak elkészítése.
                            </p>
                        </div>
                    </div>
                    <div className='card'>
                        <img className='card-image' src='/img/mydentlogo.png'/>
                        <div className='card-content'>
                            <h3 className='card-title'>
                                MYdent Fogászati Centrum
                            </h3>
                            <p className='card-description'>
                                Fogászati rendelők, recepció bútoroinak elkészítése.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='cards'>
                    <div className='card'>
                        <img className='card-image' src='/img/vbeklogo.png'/>
                        <div className='card-content'>
                            <h3 className='card-title'>
                                Váci Belvárosi Egészségügyi Központ (VBEK)
                            </h3>
                            <p className='card-description'>
                                Váci egészségügyi központ teljeskörű bebútorozása.
                            </p>
                        </div>
                    </div>
                    <div className='card'>
                        <img className='card-image' src='/img/pension.jpg'/>
                        <div className='card-content'>
                            <h3 className='card-title'>
                                Pension Bachseitenhof (Radstadt, Ausztria)
                            </h3>
                            <p className='card-description'>
                                Gyönyörű alpesi környezet, gyönyörű panzió, gyönyörű bútorok.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TileComponent;