import './style.css';
import Cover from '../../components/Cover';
import Navigation from '../../components/Navigation'
import Skills from '../../components/Skills';
import FlipSide from '../../components/FlipSide';
import ContentCircles from '../../components/ContentCircles';
import FullWidthAnimated from '../../components/FullWidthAnimated';
import TileComponent from '../../components/TileComponent';
import ScrollBackground from '../../components/ScrollBackground';



function Home () {

    return(
        <div className='home-screen'>
            <Cover/>    
            <FlipSide 
                title={'MODERN'}
                imageUrl={'/img/kitchen4.png'} 
                description={'Konyhabútoraink letisztult vonalai és innovatív megoldásai nemcsak a stílust, hanem a funkcionalitást is új szintre emelik. Tökéletes választás azoknak, akik nem elégszenek meg a hagyományossal.'}
                backgroundColor={'#212429'}
                color={'white'}
            /> 
            <FlipSide 
                title={'LETISZTULT'} 
                imageUrl={'/img/kitchen3.png'} 
                description={'Élvezd a letisztult dizájn szépségét – konyhabútoraink modern formáikkal és tiszta vonalaikkal hoznak eleganciát és rendezettséget konyhájába.'}
                backgroundColor={'#212429'}
                color={'white'}
                reverse={true} 
            />
            <FlipSide 
                title={'ELEGÁNS'} 
                imageUrl={'/img/kitchen5.png'} 
                description={'Tapasztalja meg az elegancia új dimenzióját konyhád szívében! Bútoraink kifinomult formatervezése és prémium anyagai egyedülálló stílust és luxust csempésznek a mindennapokba. Tegye még különlegesebbé a főzést egy igazán elegáns konyhával!'}
                backgroundColor={'#212429'}
                color={'white'}
            /> 
            <TileComponent/>
            <ScrollBackground reverse={true}/>
        </div>
    );

};

export default Home;