import { useState } from 'react';
import './style.css';
import { Link } from 'react-router-dom';

function Navigation (scrollIntoView) {

    const navigationItems = [

        { id:'cover', label: 'KEZDŐLAP', path: '/'},
        { id:'about-us', label: 'RÓLUNK', path: '/about-us'},
        { id:'services', label: 'SZOLGÁLTATÁSOK', path: '/services'},
        { id:'contact', label: 'KAPCSOLAT', path: '/contact'},
    ];

    /* hozz létre egy javascript funckiót onNavigationToggle néven és 
        azt írja a console-be hogy "button clicked"
        a funkció akkor legyen meghívva ha a navigation toggler gombra kattint a felhasználó
    */

    const onNavigationToggle = () => {
        setIsOpened( isOpened ? false : true);
    }


    /*
        hozz létre egy állapottal rendelkező változót a useState segítségével
        a változó neve az legyen hogy isOpened 
        azt a funkciót ami beállítja az isOpened értékét pedig setIsOpened -nek hívjuk
        az isOpened alapértéke pedig false.
    */
    const [isOpened, setIsOpened] = useState(false);

    const handleClick = () => {
        scrollToFlipSide();
        <Link/>
    }

    let scrollToFlipSide = () => {
        document.getElementById("cover").scrollIntoView({behavior:'smooth'});
        console.log('mukszik');
    }


    return (

        //<nav className='main-navigation' //style={{backgroundImage:'url(/img/navbg.jpg)'}}>
        <nav className='main-navigation'>
            <Link to={'/'}>
                <div className='main-navigation-img'>
                    <img src='/img/haffnerlogo.png' height={50} width={40}/>
                </div>
            </Link>
            <ul className='main-navigation-links' style={{display: isOpened ? 'flex' : 'none'}}>
                { navigationItems.map((navigationItem) => 
                    <li key={navigationItems.id} className='navigation-link-buttons'>
                        <Link to={navigationItem.path} onClick={() => handleClick()}>
                            <a>
                                {navigationItem.label}
                            </a>
                        </Link>
                    </li>
                )}
            </ul>
            <button className='mobile-toggler' onClick={() => onNavigationToggle()}>
                <span></span>
                <span></span>
                <span></span>
            </button>
        </nav>
        
    );
};

export default Navigation;