import { Link } from 'react-router-dom';
import './style.scss';

function ContentCircles () {
    return(
        <section className='content-circles'>
            <div className='container'>
                <div className='feature'>
                    
                    <img src='/img/kitchenpictogram.png' className='image'/>
                    <h2 className='headline'>
                        Konyhabútorok
                    </h2>
                    <p className='description'>                 
                        Konyhabútoraink megtervezése, minőségi kivitelezése!
                    </p>
                    <Link to={'/contact'}>
                        <button className='button'>
                            Kapcsolat
                        </button>
                    </Link>
                </div>
                <div className='feature best'>
                    <img src='/img/furniturepictogram.png' className='image'/>
                    <h2 className='headline'>
                        Belsőépítészet
                    </h2>
                    <p className='description'>
                        Egyéb belsőépítészeti bútorok elkészítése. 
                    </p>
                    <Link to={'/contact'}>
                        <button className='button'>
                            Kapcsolat
                        </button>
                    </Link>
                </div>
                <div className='feature'>
                    <img src='/img/windowpictogram.png' className='image'/>
                    <h2 className='headline'>
                       Fa nyílászárók
                    </h2>
                    <p className='description'>
                        Mi sem szebb egy szép fa nyílászárónál. Legyen szó ajtókról, ablakokról. 
                    </p>
                    <Link to={'/contact'}>
                        <button className='button'>
                            Kapcsolat
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default ContentCircles;