import './style.css';



function Contact (scrollIntoView) {


    return(
        <section className='contact-screen' style={{backgroundImage:'url(/img/kitchen3.png)'}}>
            <div className='container'>
                <h1 className='title'>
                    Kapcsolat
                </h1>
                <div className='contact-information-row'>
                    <div className='contact-information-box'  style={{backgroundImage:'url(/img/contactboxbg.jpg)'}}>
                        <h2 className='headline'>
                            Sárosi József
                        </h2>
                            <p className='contact-information'> 
                                <span>
                                    Telefon:
                                </span>
                                <a href='tel:+36704209233'>
                                    +36-70-420-9233
                                </a>
                            </p>
                            <p className='contact-information'> 
                                <span>
                                    E-mail:
                                </span>
                                <a href='tel:+36704209233'>
                                    info@haffnerbutor.hu
                                </a>
                            </p>
                    </div>
                    <div className='contact-screen-description'>
                        <h1 className='headline'>
                            Ha felkeltettük érdeklődését, kérjük lépjen velünk kapcsolatba!
                        </h1>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Contact;
